import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/design-system/design-system/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Tooltip } from '@entur/tooltip';
import { PrimaryButton } from '@entur/button';
import { Props } from 'docz';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { ValidationInfoFilledIcon } from '@entur/icons';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<Tooltip content=\"Tooltip på toppen\" placement=\"top\">\n  <PrimaryButton>\n    <ValidationInfoFilledIcon inline /> Denne knappen har en\n    tilleggsinformasjon\n  </PrimaryButton>\n</Tooltip>'} __scope={{
      props,
      DefaultLayout,
      Tooltip,
      PrimaryButton,
      Props,
      Playground,
      PageHeader,
      ImportStatement,
      ValidationInfoFilledIcon,
      DoDontGroup,
      DoDontCard,
      DefaultLayout,
      _frontmatter
    }} style={{
      display: 'flex',
      justifyContent: 'center'
    }} mdxType="Playground">
  <Tooltip content="Tooltip på toppen" placement="top" mdxType="Tooltip">
    <PrimaryButton mdxType="PrimaryButton">
      <ValidationInfoFilledIcon inline mdxType="ValidationInfoFilledIcon" /> Denne knappen har en tilleggsinformasjon
    </PrimaryButton>
  </Tooltip>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "tooltip"
    }}>{`Tooltip`}</h3>
    <ImportStatement imports="Tooltip" mdxType="ImportStatement" />
    <Props of={Tooltip} mdxType="Props" />
    <p>{`Du wrapper `}<inlineCode parentName="p">{`<Tooltip />`}</inlineCode>{` rundt elementet du ønsker at skal ha en tooltip.
`}<strong parentName="p">{`Obs:`}</strong>{` Plasseringen av tooltip baserer seg på `}<inlineCode parentName="p">{`ref`}</inlineCode>{`,
så pass derfor på at komponenten som wrappes støtter `}<inlineCode parentName="p">{`forwardRef`}</inlineCode>{`. Les mer om dette `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/forwarding-refs.html"
      }}>{`her`}</a>{`.`}</p>
    <p>{`Om man sender med `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` så får Tooltip en lukkeknapp for å håndtere situasjoner hvor man ønsker kontrollere visningen av Tooltip.`}</p>
    <h2 {...{
      "id": "retningslinjer-for-bruk"
    }}>{`Retningslinjer for bruk`}</h2>
    <p>{`Tooltip brukes for å beskrive eller legge til tilleggsinformasjon når brukeren hovrer eller fokuserer på
interaktive elementer som knapper, lenker, ikonknapper eller ikke-interaktive elementer som ikoner og tekst.
En tooltip bør kun ha en kortfattet setning på en enkelt tekstlinje.`}</p>
    <p>{`På mobile flater vises en tooltip først når en bruker klikker på elementet.
Den blir borte hvis brukeren klikker utenfor tooltip’en. Siden tooltips skjuler informasjonen,
så bør det brukes sparsommelig. For kompleks informasjon og setninger som går over flere tekstlinjer, bruk heller Popover eller Drawer.`}</p>
    <h3 {...{
      "id": "prinsipper"
    }}>{`Prinsipper`}</h3>
    <DoDontGroup mdxType="DoDontGroup">
  <DoDontCard title="Gjør" variant="success" textInBox mdxType="DoDontCard">
    <ul>
      <li>
        Bruk hvis du vil gi et lite hint om hvordan eller hva et element på
        siden gjør.
      </li>
      <li>Bruk tooltip sparsomt, og hold det kort og konsists,</li>
      <li>
        Bruk hvis et interaktivt element (knapp, label, ikon) har ingen
        beskrivende tekst og dette elementet trenger en kort forklaring.
      </li>
      <li>
        Bruk hvis det er lite plass, for mye informasjon til å ha det inline og
        hvis det skaper for mye rot å vise den med en gang.
      </li>
    </ul>
  </DoDontCard>
  <DoDontCard title="Unngå" variant="warning" textInBox mdxType="DoDontCard">
    <ul>
      <li>
        Unngå bruk av interaktive elementer som lenker elle rknapper i en
        tooltip. Vurder heller å bruke en Popover
      </li>
      <li>
        Unngå tooltip for å kommunisere og varsle om viktig og kritisk
        informasjon eller feilmeldinger i skjemaer. Vurder å bruk Modaler eller
        Alerts.
      </li>
      <li>
        Ikke overdriv bruken av tooltips. For mange vil enten overvelde eller
        irritere brukeren.
      </li>
    </ul>
  </DoDontCard>
    </DoDontGroup>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      